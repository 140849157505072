import React from 'react';




const NoPage = () => {

return (
    <div>
        <h2>Сторінку незнайдено</h2>
    </div>
)



}

export default NoPage;